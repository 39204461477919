@import "../../styles/tools";

$padding-outer: 30px;
$padding-inner: 24px;

$padding-outer--tablet: 24px;
$padding-inner--tablet: 20px;

$padding-outer--mobile: 20px;
$padding-inner--mobile: 14px;

@keyframes pulse {
    0% {
        background-color: #f0f0f0;
    }
    50% {
        background-color: #e0e0e0;
    }
    100% {
        background-color: #f0f0f0;
    }
}

.container {
    background: #fff;
    border-radius: $border-radius;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    & > .header, & > .content, & > .footer {
        opacity: 1;
        transition: .6s;
    }

    &.container--no-margin {
        margin-bottom: 0;
    }

    &.container-type--secondary {
        background: rgb(248, 248, 248);
    }

    &--loading {
        background-color: #f0f0f0;
        animation: pulse 1.5s infinite ease-in-out;
        width: 100%;
        & > .header, & > .content, & > .footer {
            opacity: 0;
        }
    }
}

.container--primary {
    flex: 1;
}

.container--with-shadow {
    box-shadow: 0 4px 23px rgba(191, 191, 191, 0.25);
}

.header {
    padding: $padding-outer $padding-inner $padding-inner*0.5;
    display: flex;
    align-items: center;

    &.header--border {
        border-bottom: 1px solid $color-input-background;
        margin-bottom: $padding-inner*0.5;
    }
}

.header-wrapper {
    display: flex;
    align-items: center;
}

.header__title {
    margin-bottom: 5px;
    min-height: 39px;
    display: flex;
    align-items: center;
    width: 100%;
    flex: 2;
    white-space: nowrap;

    &--no-margin {
        margin: 0;
    }
}

.header__title__text {
    color: $color-title-active;
    font-size: $font-size--xlarge;
    line-height: 28px;
    font-weight: 600;
}

.header__title__addons {
    font-size: $font-size--small;
    line-height: 20px;
    font-weight: 300;
    margin-left: 32px;
}

.header__title--small {
    flex-direction: column;
    white-space: normal;
    align-items: flex-start;

    .header__title__addons {
        margin-left: 0px;
    }

    @include media-mobile {
        white-space: nowrap;
    }
}

.header__action {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 6px;
    flex: 1;
    text-align: right;
}

.content {
    padding: $padding-inner*0.5 $padding-outer;
    flex: 1;
}

.content--without-title {
    padding-top: $padding-inner;
}

.content--without-footer {
    padding-bottom: $padding-outer;
}

.footer {
    &.footer--border {
        border-top: 1px solid $color-input-background;
    }
    
    padding: $padding-outer;
}

.header + .footer {
    border-top: none;
    margin: auto 0 0;
}

.section-container {
    margin-left: -$padding-outer;
    margin-right: -$padding-outer;

    &:first-child {
        margin-top: -$padding-inner;
    }

    &:last-child {
        margin-bottom: -$padding-inner;
    }

    &:not(:last-child):not(.section-container--no-border) {
        border-bottom: 1px solid $color-input-background;
    }

    &.section-container--no-margin {
        margin: 0 !important;
    }
}

.section-container--padding-default {
    padding: $padding-inner*0.5 $padding-outer;
}

.section-container--padding-small {
    padding: $padding-inner*0.5 $padding-outer*0.5;
}

.section-container--padding-wide {
    padding: $padding-inner*0.5 0;
}

@include media-tablet {
    .header__title {
        font-size: 18px;
    }

    .header {
        padding: $padding-outer--tablet $padding-inner $padding-inner*0.5;
    }

    .container {
        height: auto;
    }
}

@include media-mobile {
    .header {
        padding: 18px 15px;
        //height: 58px;
        padding-bottom: 10px;
    }

    .header__title {
        font-size: 16px;
        flex-direction: column;
        align-items: flex-start;
    }

    .header__title__addons {
        margin-left: 0;
        margin-top: 10px;
    }

    .content {
        padding: 15px;
    }

    .section-container {
        margin-top: -15px;
        margin-bottom: 15px;
        margin-left: -15px;
        margin-right: -15px;

        &:last-child {
            margin-bottom: -15px;
        }
    }

    .section-container--padding {
        padding: 15px 15px;
    }
    

    .footer {
        padding: 12px 15px 12px 15px;
        text-align: center;
    }
}

@include media-mobile-xs {
    .container {
        margin-left: -10px;
        margin-right: -10px;
        border-radius: 0;
        box-shadow: 0px 1px 1px 1px #00000017;
    }
}